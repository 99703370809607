import React, { useState, useEffect, useContext } from 'react';
import Header from './Header';
import { AuthContext } from './AuthContext';
import { fetchClasses } from './utils';
import axios from 'axios';
import './Home.css';

const Home = () => {
  const { user } = useContext(AuthContext);
  const [selectedClass, setSelectedClass] = useState(user?.class || '');
  const [classes, setClasses] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [userMessage, setUserMessage] = useState('');
  const [expandedCard, setExpandedCard] = useState(null); 
  const [selectedKey, setSelectedKey] = useState('Master');

  useEffect(() => {
    const getClasses = async () => {
      try {
        const folders = await fetchClasses();
        setClasses(folders);
      } catch (error) {
        console.error('Error fetching classes:', error);
        setError(error);
      }
    };

    getClasses();
  }, []);

  const fetchData = async (email, encryptedEmail, className) => {
    try {
      setLoading(true);
      const response = await axios.post('https://bvsrauoaua.execute-api.ap-south-1.amazonaws.com/getData', {
        email,
        encryptedEmail,
        class: className,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setData((response.data)); // Parse the response body
        setUserMessage('');
      } else {
        setData(null);
        setUserMessage('Your data is not present in the requested class. Contact LMS team.');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
      setData(null);
      setUserMessage('Your data is not present in the requested class. Contact LMS team.');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && selectedClass) {
      fetchData(user.email, user.encryptedEmail, selectedClass);
    }
  }, [user, selectedClass]);

  const handleClassChange = (e) => {
    const newClass = e.target.value;
    setSelectedClass(newClass);
    setData(null);
    setUserMessage('');
    if (user) {
      fetchData(user.email, user.encryptedEmail, newClass);
    }
  };

  const toggleCard = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
    setSelectedKey('Master');
  };

  const handleKeyClick = (e, key) => {
    e.stopPropagation();
    setSelectedKey(key);
  };

  useEffect(() => {
    if (user && selectedClass) {
      fetchData(user.email, user.encryptedEmail, selectedClass);
    }
  }, [user, selectedClass]);

  const renderTable = (data, key) => {
    if (!data[key]) {
      return <div>No data available for {key}</div>;
    }

    if (key === 'Score scale') {
      return (
        <table className="vertical-table">
          <thead>
            <tr>
              <th>Course Activities</th>
              <th>Weightage</th>
            </tr>
          </thead>
          <tbody>
            {data[key].map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row['Course Activities']}</td>
                <td>{row['Weightage']}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return (
      <table className="vertical-table">
        <tbody>
          {data[key].map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {Object.entries(row).map(([cellKey, cellValue], cellIndex) => (
                <tr key={cellIndex}>
                  <th>{cellKey}</th>
                  <td>{cellValue}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div>
      <Header />
      <div className="content">
        <h2>Welcome, {user?.profile?.name || 'User'}</h2>
        <p>Email: {user?.email}</p>
        <p>Class: {user?.class}</p>
        <div className="class-dropdown">
          <label htmlFor="classSelect">Select Your Class:</label>
          <select id="classSelect" value={selectedClass} onChange={handleClassChange}>
            <option value="" disabled>Select your class</option>
            {classes.map((className, index) => (
              <option key={index} value={className}>{className}</option>
            ))}
          </select>
        </div>
        {userMessage && <div className="error-message">{userMessage}</div>}
        {data && (
          <div className="data-display">
            {data.map((course, index) => (
              <div key={index} className="card" onClick={() => toggleCard(index)}>
                <div className="card-header">{course.course_name}</div>
                <div className={`card-content ${expandedCard === index ? 'expanded' : ''}`}>
                  <div className="left-pane">
                    {Object.keys(course.data).map((key, idx) => (
                      <div
                        key={idx}
                        className={`key-item ${selectedKey === key ? 'active' : ''}`}
                        onClick={(e) => handleKeyClick(e, key)}
                      >
                        {key}
                      </div>
                    ))}
                  </div>
                  <div className="right-pane">
                    <div className="segment-card">
                      <div className="segment-card-header">{selectedKey}</div>
                      {renderTable(course.data, selectedKey)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
