// src/Header.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './Header.css';

const Header = () => {
  const { user, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="header">
      <Link to="/home">
        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="logo" />
      </Link>
      <h1 className="title">Dashboard</h1>
      {user && (
        <button onClick={handleLogout} className="logout-button">Logout</button>
      )}
    </header>
  );
};

export default Header;
